
export default {
  name: "Donation",
  props: ["position"],
  components: {
      CheckBox : () => import("@/components/donation/CheckBox"),
      FixedAmountLine : () => import("@/components/donation/FixedAmountLine"),
    },
  data() {
    return {
      donationTypes: [
        {
          id: "once",
          name: "Don ponctuel",
        },
        {
          id: "monthly",
          name: "Don régulier",
        },
        {
          id: "ifi",
          name: "IFI",
        },
      ],
      selectedDonation: "once",
      selectedAmount: null,
      donationsData: null,
      submitText: "Je donne maintenant"
    };
  },
  async fetch() {
    const settings = await this.$store.$defaultRepository.getByUrl(
      "rcf_admin/settings"
    );

    if (settings) {
      const donationTypes = ["once", "monthly", "ifi"];
      const donations = {};

      donationTypes.forEach((type) => {
        donations[type] = {
          type,
          freeAmount: "",
          low: settings[`lower_amount_${type}`] || null,
          middle: settings[`middle_amount_${type}`] || null,
          upper: settings[`upper_amount_${type}`] || null,
          taxDiscount: settings[`tax_discount_${type}`],
          taxDiscountText: settings[`tax_discount_text_${type}`],
          link: settings[`donation_link_${type}`],
        };
      });

      this.submitText = settings.submit_label
      this.donationsData = donations;
    }
  },
  methods: {
    selectDonation(donation) {
      this.selectedDonation = donation;
      this.selectedAmount = null
    },
    checkInteger(event) {
      const disallowedKeys = [".", ",", "e", "-", "+"];
      if (disallowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    selectAmount(amount) {
      this.selectedAmount = amount
      if (amount === 'low') {
        this.$refs.freeAmountInput.focus()
      }
    },
    isSelected(type) {
      return this.selectedAmount === type
    },
    generateDiscountText(type) {
      const defaultString = this.donationsData[this.selectedDonation].taxDiscountText
      let value;

      if (type === 'low') {
        value = this.donationsData[this.selectedDonation].freeAmount || this.donationsData[this.selectedDonation].low
      } else {
        value = this.donationsData[this.selectedDonation][type]
      }

      const valueAfterTax = Number(value) * Number(this.donationsData[this.selectedDonation].taxDiscount)
      const newElement = `<span class='text-red-700 font-semibold'>${valueAfterTax.toFixed(2)} €</span>`
      const generatedString = defaultString.replace("%amount €", newElement)

      return generatedString
    },
    handleSubmit() {
      window.open(this.donationUrl, "_blank", "noopener,noreferrer" )
    }
  },
  computed: {
    isDisabled() {
      if (!this.selectedAmount || (this.selectedAmount === 'low' && !this.donationsData[this.selectedDonation].freeAmount)) return true
      return false
    },
    donationUrl() {
      let radio_code = this.$store.state.radio.radioCode ?? this.$config.nationalCode
      if (this.donationsData) {
        const amount = this.selectedAmount === 'low' ? this.donationsData[this.selectedDonation].freeAmount : this.donationsData[this.selectedDonation][this.selectedAmount]
        return `${this.donationsData[this.selectedDonation].link}?radio=${radio_code}&amount=${Number(amount)}${this.selectedDonation === 'ifi' ? '&fiscalite=ifi' : ''}${this.selectedDonation === 'monthly'? '&frequency=1' : ''}`
      }

      return ""
    }
  }
};
